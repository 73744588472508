import React, { Component } from 'react';

class Dashboard extends Component {
	render() {
		return (
			<div>
				<h3>White Falcon Dashboard</h3>
			</div>
		);
	}
}

export default Dashboard;
