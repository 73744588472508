import React, { useState, useEffect } from 'react';
import { CButton, CCard, CCardBody, CCardHeader, CCardGroup, CCol, CContainer, CForm, CInput, CInputGroup, CInputGroupPrepend, CInputGroupText, CRow } from '@evenlogics/react'
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
function CustomLogin(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [otpLoading, setOtpLoading] = useState(false)
    const [resetLoading, setResetLoading] = useState(false)

    const [showforgetPassModal, setShowForgetPassModal] = useState(false)
    const [showResetPassModal, setShowResetPassModal] = useState(false)
    const [email, setEmail] = useState('')
    const [newpass,setNewpass] =useState('')
    const [otp,setOtp]=useState('')
    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');
        console.log(currentUser, "currentUser");

        if (currentUser) {
            props.history.push("/dashboard");
        }
    }, [props.history])
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/public/auth`, {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: { "Content-type": "application/json;charset=UTF-8" }
        })
            .then(async response => {
                if (response.status >= 200 && response.status <= 299) {
                    let data = await response.json()
                    if (data.data.api_token) {

                        localStorage.setItem('currentUser', JSON.stringify({...data.data,authToken:data.data.api_token}));
                        toast.success('Login Successfull', { autoClose: 3000 });
                        // props.history.push("/dashboard");
                        window.location.reload()
                        return true

                    }
                } else {
                    let data = await response.json();
                    toast.error(data.error, { autoClose: 3000 });
                    return false;
                }
            }).catch(err => {
                console.log(err, "err")
                toast.error("Authorization Failed!", { autoClose: 3000 });

            }).finally(() => setLoading(false));
    };



    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            await handleLogin(e);
        }
    };

    const openForgetPasswordModal = () => {
        setShowForgetPassModal(true)
    }
    const sendOtp = () => {

        setOtpLoading(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/public/password/reset/email`, {
            method: "POST",
            body: JSON.stringify({email }),
            headers: { "Content-type": "application/json","accept":"application/json" }
        })
            .then(async response =>{
                    try {
                        let data = await response.json()
                        if (response.status >= 200 && response.status <= 299) {
                            console.log(data)
                            setShowResetPassModal(true)
                            toast.success('OTP has been sent to your email', { autoClose: 3000 });
    
                        } else{
                            toast.error(data?.errors?.email[0]||'Something went wrong', { autoClose: 3000 });
                        }   
                    } catch (error) {
                        console.log(error)
                    }
                    
                
            }).catch(err => {

                console.log(err, "err")
                toast.error("Something Went Wrong", { autoClose: 3000 });

            }).finally(() => setOtpLoading(false));
    }


    const resetPassword = () => {
        setResetLoading(true)
        const payload={
            otp,
            email,
            password:newpass,
            password_confirmation:newpass
        }
        console.log(payload)
        fetch(`${process.env.REACT_APP_BASE_URL}/public/password/reset`, {
            method: "POST",
            body: JSON.stringify({...payload}),
            headers: { "Content-type": "application/json","accept":"application/json" }
        })
            .then(async response => {
                let data = await response.json()
                if (response.status >= 200 && response.status <= 299) {
                    console.log(data)
                    toast.success('Password has been reset', { autoClose: 3000 });
                    setShowResetPassModal(false)
                    setShowForgetPassModal(false)
                }else{
                    const key =Object.keys(data?.errors)[0]
                    toast.error(data?.errors[key][0]||'Something went wrong', { autoClose: 3000 });
                }
            }).catch(err => {

                console.log(err, "err")
                toast.error("Something Went Wrong", { autoClose: 3000 });

            }).finally(() => setResetLoading(false));
    }

    return (
        <div className="c-app c-default-layout flex-row align-items-center login-page">
            <Modal isOpen={showforgetPassModal} toggle={() => setShowForgetPassModal(!showforgetPassModal)}>
                <ModalHeader>
                    Forgot You Password?
                </ModalHeader>
                <ModalBody>
                    <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                            <CInputGroupText>
                                <i className='fas fa-user text-warning' />
                            </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="text" placeholder={'Your email address'} value={email} onChange={(e) => setEmail(e.target.value)} autoComplete />
                    </CInputGroup>
                    <CRow>
                        <CCol xs="6">
                            {
                                otpLoading ?
                                    <Spinner animation="border" role="status"></Spinner> :
                                    <CButton color="primary" className="px-4" onClick={sendOtp}>Send</CButton>
                            }
                        </CCol>

                    </CRow>

                </ModalBody>
            </Modal>


            <Modal isOpen={showResetPassModal} toggle={() => setShowResetPassModal(!showResetPassModal)}>
                <ModalHeader>
                    Forgot You Password?
                </ModalHeader>
                <ModalBody>

                    <CInput type="text" placeholder={'Your email address'} value={email} hidden autoComplete />
                    <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                            <CInputGroupText>
                                <i className='fas fa-user text-warning' />
                            </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="text" placeholder={'OTP'} onChange={(e)=>setOtp(e.target.value)} />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                            <CInputGroupText>
                                <i className='fas fa-lock text-warning' />
                            </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="password" placeholder={'Your New Password'} onChange={(e)=>setNewpass(e.target.value)} />
                    </CInputGroup>
                    <CRow>
                        <CCol xs="6">
                            {
                                resetLoading ?
                                    <Spinner animation="border" role="status"></Spinner> :
                                    <CButton color="primary" className="px-4" onClick={resetPassword}>Send</CButton>
                            }
                        </CCol>

                    </CRow>

                </ModalBody>
            </Modal>





            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardHeader style={{ textAlign: 'center' }}>

                                </CCardHeader>
                                <CCardBody>
                                    <CForm>
                                        <h1>{props.t('user:login-title')}</h1>
                                        <p className="text-muted">{props.t('user:login-subtitle')}</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <i className='fas fa-user text-warning' />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput type="text" placeholder={props.t('user:username')} autoComplete="username" id='username' value={username} onKeyDown={(e) => handleKeyPress(e)} onChange={(e) => setUsername(e.target.value)} disabled={loading} />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <i className='fas fa-key text-warning' />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput type="password" placeholder={props.t('user:password')} autoComplete="current-password" id='password' onKeyDown={(e) => handleKeyPress(e)} value={password} onChange={(e) => setPassword(e.target.value)} disabled={loading} />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CButton color="primary" className="px-4" onClick={handleLogin}>{props.t('user:login')}</CButton>
                                            </CCol>
                                            <CCol xs="6" className="text-right">
                                                <CButton color="link" className="px-0" onClick={openForgetPasswordModal} >Forgot password?</CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>

                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
}

export default withTranslation(['base', 'user'])(CustomLogin);
